import { useState } from "react";
import { DatePicker, Modal, Row, Col } from 'antd';
import * as dayjs from 'dayjs';



export const VerifyModal = ({ isModalVisible, handleCancel, selectedUser }) => {
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [images, setImages] = useState([]);

    const handleDateChange = (date) => {
        if (date) {
            const formattedDate = date.format('YYYYMMDD');
            const newImages = Array.from(
                { length: 6 },
                (_, index) => `${process.env.BUCKET_ENDPOINT}/${selectedUser}/${formattedDate}/image${index + 1}.png`,
            );
            setSelectedDate(formattedDate);
            setImages(newImages);
        } else {
            setImages([]);
        }
    };

    return (
        <Modal title="Image Viewer" open={isModalVisible} onCancel={handleCancel} width={1000} footer={null}>
            <div style={{ marginBottom: 16 }}>
                <DatePicker format="YYYY-MM-DD" onChange={handleDateChange} style={{ width: '100%' }} />
            </div>
            <Row gutter={16}>
                {images.map((imageUrl, index) => (
                    <Col span={8} key={index} style={{ marginBottom: 16 }}>
                        <img
                            src={imageUrl}
                            alt={`Image ${index + 1}`}
                            style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                            onError={(e) => {
                                e.target.style.display = 'none';
                            }}
                        />
                    </Col>
                ))}
            </Row>
        </Modal>
    );
};