import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

const EmoRadar = ({ data }) => {
  const chartComponentRef = useRef(null);

  const options = {
    chart: {
      polar: true,
    },
    title: {
      text: '',
    },
    pane: {
     
    },
    xAxis: {
      categories: data?.avg?.labels || [],
      tickmarkPlacement: 'on',
      lineWidth: 0,
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      tickPositions: [0, 20, 40, 60, 80, 100],
    },
    tooltip: {
      shared: false,
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'area',
        name: '今回',
        data: data?.closestDay?.values || [],
        color: 'rgba(0,227,150,1)',
        fillColor: 'rgba(0,227,150,.05)',
        marker: {
          radius: 7,
        },
      },
      {
        type: 'area',
        name: '平均',
        data: data?.avg?.values || [],
        color: 'rgba(0,143,251,1)',
        fillColor: 'rgba(0,143,251,.05)',
        marker: {
          radius: 6,
        },
      },
    ],
  };

  return (
    <div className='w-full h-[95%] m-auto flex flex-col justify-center items-center align-middle min-h-[400px] p-8'>
      <span className='text-[14px] text-center w-full'>各ストレスと健康分析結果</span>
      <div className='w-full h-full m-auto'>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
          containerProps={{ style: { width: '100%', height: '100%' } }}
        />
      </div>
      <span className='text-center'>※値が大きいほどストレスが大きく不健康</span>
    </div>
  );
};

export default EmoRadar;
