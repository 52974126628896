import React, { useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, PointElement, LinearScale, Title } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import 'chartjs-plugin-annotation';

ChartJS.register(Tooltip, Legend, PointElement, LinearScale, Title, Annotation);

const ScatterDensityWithArrows = ({ data, isShowArrow }) => {
  const [showArrows] = useState(isShowArrow);

  const pointsData = data.map(([timestamp, stress, emo, color, uid]) => ({
    x: emo,
    y: stress,
    date: new Date(timestamp).toISOString().split('T')[0],
    backgroundColor: color,
    uid: uid,
  }));

  const sortedPoints = pointsData.sort((a, b) => new Date(a.date) - new Date(b.date));

  const today = new Date().toISOString().split('T')[0];
  let closestPoint = null;
  let minDiff = Infinity;

  sortedPoints.forEach(point => {
    const diff = Math.abs(new Date(today) - new Date(point.date));
    if (diff < minDiff) {
      minDiff = diff;
      closestPoint = point;
    }
  });

  if (closestPoint && isShowArrow) {
    closestPoint.backgroundColor = 'yellow';
  }

  const arrowAnnotations = sortedPoints.slice(0, -1).map((point, index) => {
    const nextPoint = sortedPoints[index + 1];
    return {
      type: 'arrow',
      xMin: point.x,
      xMax: nextPoint.x,
      yMin: point.y,
      yMax: nextPoint.y,
      borderColor: 'rgba(0,0,0,0.4)',
      borderWidth: 1,
      arrowHeads: {
        display: true,
        fill: true,
        size: 6,
        end: {
          display: true,
          fill: true,
        },
        start: {
          display: false,
        },
      },
    };
  });

  const chartData = {
    datasets: [
      {
        label: 'Scatter Points',
        data: sortedPoints,
        pointRadius: 5,
        backgroundColor: sortedPoints.map(point => point.backgroundColor),
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'ストレス要因と周囲のサポート',
        },
        min: 26,
        max: 104,
      },
      y: {
        title: {
          display: true,
          text: 'ストレス反応',
        },
        min: 29,
        max: 116,
      },
    },
    plugins: {
      annotation: {
        annotations: {
          ...(showArrows ? arrowAnnotations.reduce((acc, arrow, index) => {
            acc[`arrow${index}`] = arrow;
            return acc;
          }, {}) : {}),
          box1: {
            type: 'box',
            xMin: 26,
            xMax: 104,
            yMin: 77,
            yMax: 116,
            backgroundColor: 'rgba(28, 170, 238, 0.2)',
            borderColor: 'rgba(28, 170, 238, 1)',
          },
          box2: {
            type: 'box',
            xMin: 76,
            xMax: 104,
            yMin: 63,
            yMax: 77,
            backgroundColor: 'rgba(28, 170, 238, 0.2)',
            borderColor: 'rgba(28, 170, 238, 1)',
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const point = context.raw;
            if (isShowArrow) {
                return `ストレス: ${point.y}, サポート: ${point.x}, 日付: ${point.date}`;
              } else {
                // return `ストレス: ${point.y}, サポート: ${point.x}, UID: ${point.uid}`;
                return `ストレス: ${point.y}, サポート: ${point.x}`;
            }
          },
        },
        mode: 'nearest',
        intersect: false,
        showMarkers: true,
        shared: true,
      },
      interactions: [{ type: 'marker-active' }],
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', maxHeight: '400px' }}>
      <Scatter data={chartData} options={options} />
    </div>
  );
};

export default ScatterDensityWithArrows;
