import { useState } from 'react';
import { getListUsers, getStatisticUser, getUserVerification, getlistDepartment, getUserStressScore } from '../requests/user.request';

const useStatisticData = () => {
  const getDate = (type) => {
    const date = new Date();
    if (type === 'start') {
      date.setDate(date.getDate() - 30);
    }
    date.setSeconds(date.getSeconds() - 1);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [data, setData] = useState({
    rawData: [],
    scatterData: [],
    latestScores: [],
    overAll: {},
    radar: {
      avg: { labels: [], values: [] },
      closestDay: { labels: [], values: [] }
    },
    lineData: [],
  });
  const [datepicker, setDatePicker] = useState({ start: getDate('start'), end: getDate('end') });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyData, setVerifyData] = useState([]);
  const [departments, setDepartments] = useState([{ value: '全部署', label: '全部署' }]);
  const [department, setDepartment] = useState(departments[0].value);

  const fetchUsers = async () => {
    const data = await getListUsers();
    if (Array.isArray(data.content)) {
      setUserOptions(
        data.content.map((e) => ({
          value: e.user_id,
          label: e.user_id + ' ' + e.name,
        })),
      );
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await getlistDepartment();
      const departmentMap = response.content.map((each) => ({ value: each, label: each }));
      setDepartments([{ value: '全部署', label: '全部署' }, ...departmentMap]);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
    }
  };

  const fetchData = async (user_id = null, department = null) => {
    setIsLoading(true);
    const form = { 
      start: datepicker.start || getDate('start'), 
      end: datepicker.end || getDate('end'),
      ...datepicker,
    };
    if (user_id) form.user_id = user_id; 
    form.department = department && department !== '全部署' ? department : 'All';

    const queryString = new URLSearchParams(form).toString();

    try {
      const data = await getStatisticUser(queryString);
      
      if (data) {
        const processedData = processUserData(data);
        setData(processedData);
        
      } else {
        setData({
          rawData: [],
          scatterData: [],
          overAll: {},
          radar: {
            avg: { labels: [], values: [] },
            closestDay: { labels: [], values: [] }
          },
          lineData: [],
          avgLatest: {},
        });
      }

      const lineData = await getUserStressScore(queryString);
      setData((prev) => ({ ...prev, lineData: lineData.latest_scores }));
      
    } catch (error) {
      setData({
        rawData: [],
        scatterData: [],
        overAll: {},
        radar: {
          avg: { labels: [], values: [] },
          closestDay: { labels: [], values: [] }
        },
        lineData: [],
        avgLatest: {},
      });
    } finally {
      setIsLoading(false);
    }
  };

  const verifyUser = async () => {
    try {
      setVerifyData([]);
      setVerifyLoading(true);
      let form = { ...datepicker, selectedUser };
      const data = await getUserVerification(form);
      if (data?.length > 0) {
        setVerifyData(data);
        setTimeout(() => {
          setVerifyLoading(false);
          setIsModalVisible(true);
        }, 500);
      } else {
        alert('No data found');
        setVerifyLoading(false);
      }
    } catch (error) {
      setVerifyData([]);
      setVerifyLoading(false);
    }
  };

  const processUserData = (content) => {
    const scatterData = content?.latest_scores?.map((entry) => {
      const date = entry.date || new Date().toISOString().split('T')[0];
      const stressScore = entry.stressB;
      const emoScore = entry.stressA + entry.stressC;
      let pointColor = '#ff99bd';
      if (stressScore > 76) pointColor = '#03b1fc';
      if (stressScore > 66 && emoScore > 76) pointColor = '#03b1fc';
      const uid = entry.user_id || '';

      return [date, stressScore, emoScore, pointColor, uid];
    }) || [];

    const calculatePercentage = (value, max) => {
      return Math.round((value / max) * 100);
    }
    const radar = {
      avg: {
        labels: [
          'ストレス A',
          'ストレス B',
          'ストレス C',
          '声',
          '顔',
        ],
        values: [
          calculatePercentage(content.avg_avg.stressA, 68 || 0),
          calculatePercentage(content.avg_avg.stressB, 116 || 0),
          calculatePercentage(content.avg_avg.stressC, 36 || 0),
          100-calculatePercentage(content.avg_avg.voice, 100 || 0),
          100-calculatePercentage(content.avg_avg.face, 100 || 0)
        ],
      },
      closestDay: {
        labels: [
          'ストレス A',
          'ストレス B',
          'ストレス C',
          '声',
          '顔',
        ],
        values: [
          calculatePercentage(content.avg_latest.stressA, 68 || 0),
          calculatePercentage(content.avg_latest.stressB, 116 || 0),
          calculatePercentage(content.avg_latest.stressC, 36 || 0),
          100-calculatePercentage(content.avg_latest.voice, 100 || 0),
          100-calculatePercentage(content.avg_latest.face, 100 || 0)
        ],
      }
    };

    return {
      rawData: content,
      scatterData,
      latestScores: content.latest_scores[content.latest_scores.length - 1] || {},
      radar,
      avgLatest: content.avg_latest || {},
    };
  };

  return {
    userOptions,
    selectedUser,
    setSelectedUser,
    data,
    isLoading,
    datepicker,
    setDatePicker,
    verifyLoading,
    verifyUser,
    isModalVisible,
    setIsModalVisible,
    verifyData,
    getDate,
    fetchData,
    fetchUsers,
    department,
    setDepartment,
    departments,
    fetchDepartments,
  };
};

export default useStatisticData;
