import React from 'react';
import noData from '../assets/img/nodata.png';

const NoData = () => {
    return (
        <div className='w-full h-full flex flex-col justify-center items-center align-middle'>
            <img className='w-[350px]' src={noData} alt='データなし' />
            <h1 className='text-gray-400 text-[30px]'>おっと！</h1>
            <p className='text-gray-400'>まだデータがないようです</p>
        </div>
    );
};

export default NoData;