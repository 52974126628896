import { message } from 'antd';
import axios from 'axios';

export const createApiInstance = (config, { token, silent = false } = {}) => {
    const api = axios.create(config);

    api.interceptors.request.use(
        (config) => {
            if (token && config?.headers) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        },
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            // if (error.response?.status === 401) {
            //     removeAccessToken();
            // }

            if (!silent) {
                message.error(error.response?.data?.message);
            }

            return Promise.reject(error);
        },
    );

    return api;
};

export const api = createApiInstance({
    // baseURL: process.env.API_ENDPOINT,
    baseURL: 'https://aimereceptiondm3.aimesoft.com:34643/ai',
    timeout: 2 * 60 * 1000,
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
    },
});
