import { DatePicker, Space } from 'antd';
import { useEffect } from 'react';
import * as dayjs from 'dayjs';
import CustomSelect from '../../components/Customize/CustomSelect.jsx';
import ReportPage from '../report/ReportPage.jsx';
import useStatisticData from '../../hooks/useStatisticData.js';
import { StaffScoreLine } from '../../components/StaffScoreLine.jsx';
import { VerifyModal } from '../../components/VerifyModal.jsx';
import NoData from '../../components/NoData.jsx';

const { RangePicker } = DatePicker;

export const IndividualPage = () => {
    const {
        userOptions,
        selectedUser,
        setSelectedUser,
        data,
        isLoading,
        datepicker,
        setDatePicker,
        // verifyLoading,
        // verifyUser,
        // verifyData,
        isModalVisible,
        setIsModalVisible,
        fetchData,
        fetchUsers,
        getDate,
    } = useStatisticData();

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setDatePicker({ start: getDate('start'), end: getDate('end') });
        fetchUsers();
    }, []);
    
    useEffect(() => {
    if (selectedUser && datepicker.start && datepicker.end) {
        fetchData(selectedUser, null);
    }
    }, [selectedUser, datepicker]);

    return (
        <div className="individual h-full">
            <div className="filter">
                <Space direction="horizontal">
                    <CustomSelect options={userOptions} onChange={setSelectedUser} />
                    <RangePicker
                        defaultValue={[dayjs(datepicker.start), dayjs(datepicker.end)]}
                        allowClear={true}
                        onChange={(d, s) => setDatePicker({ start: s[0], end: s[1] })}
                    />
                    {/* <Button
                        onClick={verifyUser}
                        loading={verifyLoading}
                        disabled={!selectedUser || !datepicker.start || !datepicker.end}
                    >
                        ユーザー認証
                    </Button> */}
                </Space>
            </div>
            {data && (data.scatterData?.length > 0 || data.lineData?.length > 0) ? (
                <div className='flex flex-wrap w-full'>
                    {data.scatterData?.length > 0 && (
                        <ReportPage data={data} />
                    )}
                    {data.lineData?.length > 0 && (
                        <StaffScoreLine data={data.lineData} loading={isLoading} type='individual' />
                    )}
                </div>
            ) : <NoData />}
            <VerifyModal isModalVisible={isModalVisible} handleCancel={handleCancel} selectedUser={selectedUser} />
        </div>
    );
};

