import { DatePicker, Space, Spin, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Column, Line } from '@ant-design/charts';
import { COLOR } from '../../constants/color.constant.js';
import * as dayjs from 'dayjs';
import CustomSelect from '../../components/Customize/CustomSelect.jsx';
import useStatisticData from '../../hooks/useStatisticData.js';
import ReportPage from '../report/ReportPage.jsx';
import { StaffScoreLine } from '../../components/StaffScoreLine.jsx';
import NoData from '../../components/NoData.jsx';

const { RangePicker } = DatePicker;

export const LoadingBox = () => {
    return (
        <div className="flex h-full">
            <div className="m-auto">
                <Spin />
            </div>
        </div>
    );
};

export const GeneralPage = () => {
    const {
        data,
        isLoading,
        department,
        departments,
        fetchDepartments,
        fetchData,
        setDatePicker,
        getDate,
        datepicker,
    } = useStatisticData(); 

    useEffect( () => {
        fetchDepartments();
        fetchData(null, 'All');
    }, []);
    
    const handleChangeDepartment = (value) => {
        fetchData(null, value);
    };
    
    useEffect(() => {
        if (department && getDate('start') && getDate('end')) {
            fetchData(null, department);
        }
    }, [department, datepicker]);

    return (
        <div className="general h-full">
            <div className="filter">
                <Space direction="horizontal">
                    <CustomSelect defaultValue={department} options={departments} onChange={handleChangeDepartment} />
                    <RangePicker
                        defaultValue={[dayjs(datepicker.start), dayjs(datepicker.end)]}
                        allowClear={true}
                        onChange={(_d, s) => setDatePicker({ start: s[0], end: s[1] })}
                    />
                </Space>
            </div>
            {data && (data.scatterData?.length > 0 || data.lineData?.length > 0) ? (
                <div className='flex flex-wrap w-full'>
                    {data.scatterData?.length > 0 && (
                        <ReportPage data={data} page='general'/>
                    )}
                    {data.lineData?.length > 0 && (
                        <StaffScoreLine data={data.lineData} loading={isLoading} type='general' />
                    )}
                </div>
            ) : <NoData />}
        </div>
    );
};

export const LineMultipleComponent = ({ data }) => {
    const [option, setOption] = useState('day');
    const [lineData, setLineData] = useState(data[option] || []);

    useEffect(() => {
        setLineData(data[option] || []);
    }, [data]);

    const handleChange = (val) => {
        setOption(val);
        setLineData(data[val] || []);
    };

    const transformLineDatatoBar = (data) => {
        return data.map(item => ({
            ...item,
            type: `${item.type} (${item.time})`
        }));
    };

    const renderChart = () => {
        if (lineData && lineData.length <= 3) {
            const barData = transformLineDatatoBar(lineData);
            return (
                <Column
                    data={barData}
                    xField="type"
                    yField="score"
                    seriesField="type"
                    animation={{
                        appear: {
                            animation: 'scale-in',
                            duration: 2000,
                        },
                    }}
                    color={[COLOR.physical, COLOR.mental, COLOR.sentiment]}
                />
            );
        } else {
            return (
                <Line
                    data={lineData}
                    padding="auto"
                    xField="time"
                    yField="score"
                    seriesField="type"
                    animation={{
                        appear: {
                            animation: 'path-in',
                            duration: 2000,
                        },
                    }}
                    lineStyle={{
                        lineWidth: 2
                    }}
                    color={[COLOR.physical, COLOR.mental, COLOR.sentiment]}
                />
            );
        }
    };

    return (
        <div>
            <div className="flex justify-end">
                <Select
                    className="w-15"
                    defaultValue={option}
                    options={[
                        {
                            value: 'day',
                            label: '日',
                        },
                        {
                            value: 'month',
                            label: '月',
                        },
                        {
                            value: 'year',
                            label: '年',
                        },
                    ]}
                    onChange={handleChange}
                />
            </div>
            {renderChart()}
        </div>
    );
};
