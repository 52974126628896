import React from 'react';
import EmoRadar from './EmoRadar';
import ScatterDensityIndividual from './ScatterDensityIndividual';

const ReportPage = ({ data, page='individual' }) => {
  const latestScores = data?.latestScores;
  const radar = data?.radar;
  const scatterData = data?.scatterData;
  const avgLatest = data?.avgLatest;


  return (
    <>
      {
      data && scatterData  && latestScores && radar &&
      (<>
        {
        <div className='overflow-hidden p-3 w-full lg:w-1/2'>
          <div className="h-full w-full card rounded-12 p-8 bg-white">
            <span className='text-[20px]'>あなたのストレス位置図</span>
            <div className='flex flex-col'>
              <span className='text-[14px]'>◇あなたのストレス点数は、下表の●の位置です。</span>
              <span className='text-[14px]'>青ゾーンの中に●がある場合は、「高ストレス者」となっています。</span>
            </div>
            <ScatterDensityIndividual data={scatterData} isShowArrow={page === 'individual'} />
          </div>
        </div>
        }

        <div className='overflow-hidden p-3 w-full lg:w-1/2'>
        <div className="h-full w-full card rounded-12 p-8 bg-white overflow-hidden">
          <span className='text-[20px]'>あなたのストレス点数</span>
          <div className='flex flex-col'>
            <span className='text-[14px]'>♢基準となる57個の質問に回答し終えた時点での</span>
            <span className='text-[14px]'>各ストレス因子に該当する項目の点数を算出しています。</span>
          </div>
          <table className='w-full h-[90%]'>
            <tr className='border-black'>
              <td></td>
              <td className='text-center border border-neutral-400 border-r-0'><span>あなた</span></td>
              <td className='text-center border border-neutral-400 border-l-0'><span>(最小~最大)</span></td>
            </tr>
            <tr>
              <td className='border border-neutral-400'>
                <div className='flex flex-col px-6'>
                  <span>A</span>
                  <span className='text-[14px]'>仕事のストレス要因</span>
                </div>
              </td>
              <td className='text-center border border-neutral-400 border-r-0'>
                <span>
                  { ((page === 'general' ? avgLatest?.stressA : latestScores?.stressA) || 0).toFixed(2)}
                </span>
              </td>
              <td className='text-center border border-neutral-400 border-l-0'>
                <span>(17~68)</span>
              </td>
            </tr>
            <tr>
              <td className='border border-neutral-400 border-r-0'>
                <div className='flex flex-col px-6'>
                  <span>B</span>
                  <span className='text-[14px]'>心身のストレス反応</span>
                </div>
              </td>
              <td className='text-center border border-neutral-400 border-r-0'>
                <span>
                  { ((page === 'general' ? avgLatest?.stressB : latestScores?.stressB) || 0).toFixed(2) }
                </span>
              </td>
              <td className='text-center border border-neutral-400 border-l-0'>
                <span>(29~116)</span>
              </td>
            </tr>
            <tr>
              <td className='border border-neutral-400 border-r-0'>
                <div className='flex flex-col px-6'>
                  <span>C</span>
                  <span className='text-[14px]'>周囲のサポート</span>
                </div>
              </td>
              <td className='text-center border border-neutral-400 border-r-0'>
                <span>
                  { ((page === 'general' ? avgLatest?.stressC : latestScores?.stressC) || 0).toFixed(2) }
                </span>
              </td>
              <td className='text-center border border-neutral-400 border-l-0'>
                <span>(9~36)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div className='flex flex-col'>
                  <span className='text-[14px]'>※57個の質問に回答し終えた時点で、スコアが更新されます。</span>
                  <span className='text-[14px]'>※点数が高いほどストレス度が高くなります。</span>
                </div>
              </td>
            </tr>
          </table>
          </div>
        </div>
        <div className='overflow-hidden p-3 w-full lg:w-1/2 flex'>
          <div className="h-full w-full card rounded-12 bg-white flex flex-col m-auto">
            <EmoRadar data={radar} />
          </div>
        </div>

      </>)
      }
    </>
  );
};

export default ReportPage;
