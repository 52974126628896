import { api } from '../utils/api.util.js';

export const getUserVerification = async (form) => {
    const bucketUrl = process.env.BUCKET_ENDPOINT;
    const { start, end, selectedUser } = form;
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };
    const getVerifyImages = (startDate, endDate) => {
        const verifyImages = [];
        let currentDate = new Date(startDate);
        endDate = new Date(endDate);

        while (currentDate <= endDate) {
            currentDate.setDate(currentDate.getDate() + 1);
            let images = [];
            for(let i = 0; i < 6; i++){
                images.push(`${bucketUrl}/${selectedUser}/${formatDate(currentDate)}/image${i + 1}.png`);
            }
            verifyImages.push({ date: currentDate, img: images });
        }
        
        return verifyImages;
    };
    return getVerifyImages(start, end);
};

export const getListUsers = async () => {
    const { data } = await api.get('/dashboard/get_all_user');
    return data;
};

export const getlistDepartment = async () => {
    const { data } = await api.get('/dashboard/get_all_department');
    return data;
}

export const getStatisticUser = async (queryString) => {
        const { data } = await api.get(`/dashboard/get_score?${queryString}`);
        return data;
};

export const getUserStressScore = async (queryString) => {
    const { data } = await api.get(`/dashboard/get_stress_score?${queryString}`);
    return data;
}

export const getStatisticAll = async (queryString) => {
    const defaultResponse = {
        general: {
            general_health: 0,
            general_sentiment: 0,
            physical: 0,
            mental: 0,
        },
        staff_score: {
            stress_score: [],
            voice_score: [],
            question_score: [],
            final_health_score: [],
        },
    };
    try {
        const { data: { content } } = await api.get(`/dashboard/statistic_all?${queryString}`);
        if (content) return content;
        return defaultResponse;
    } catch (error) {
        return defaultResponse;
    }
};

export const uploadFileData = async (formData) => {
    const { data } = await api.post('/upload', formData, {
        headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}` },
    });
    return data;
};

export const exportData = async (format) => {
    return await api.get('/export_data', {
        params: {
            output_format: format
        },
        headers: { 'Accept': 'application/json' },
        responseType: 'blob'
    });
};
