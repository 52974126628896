import { Column, Line } from '@ant-design/charts';
import { LoadingBox } from '../pages/general/GeneralPage.jsx';
import { COLOR } from '../constants/color.constant';
import { useEffect, useState } from 'react';
import useStatisticData from '../hooks/useStatisticData.js';

export const StaffScoreLine = ({ data, loading, type='individual' }) => {
    const [chartData, setChartData] = useState([]);
    const [isLineChart, setIsLineChart] = useState(false);
    const { isLoading } = useStatisticData();

    useEffect(() => {
        if (!data || data.length === 0) {
            setChartData([]);
            setIsLineChart(false);
            return;
        }

        setIsLineChart(data.length > 1);
        let mappedData = [];

        mappedData = data.flatMap((item) => {
            const date = new Date(item.created_at);
            if (isNaN(date.getTime())) {
                console.warn('Invalid date:', item.created_at);
                return [];
            }

            return [
                {
                    type: 'ストレス A',
                    date: date.toISOString(),
                    score: Math.round((item.stressA_score || 0) * 100) / 100,
                },
                {
                    type: 'ストレス B',
                    date: date.toISOString(),
                    score: Math.round((item.stressB_score || 0) * 100) / 100,
                },
                {
                    type: 'ストレス C',
                    date: date.toISOString(),
                    score: Math.round((item.stressC_score || 0) * 100) / 100,
                },
            ];
        });

        setChartData(mappedData);
    }, [data]);

    let stressAData = chartData.filter((item) => item.type === 'ストレス A' && item.score !== 0).sort((a, b) => new Date(a.date) - new Date(b.date));
    let stressBData = chartData.filter((item) => item.type === 'ストレス B' && item.score !== 0).sort((a, b) => new Date(a.date) - new Date(b.date));
    let stressCData = chartData.filter((item) => item.type === 'ストレス C' && item.score !== 0).sort((a, b) => new Date(a.date) - new Date(b.date));

    if (type !== 'individual') {
        const averageByDate = (data, type) => {
            const groupedByDate = data.reduce((acc, item) => {
                const date = item.date.split('T')[0];
                if (!acc[date]) {
                    acc[date] = { date: item.date, score: 0, count: 0, type };
                }
                acc[date].score += item.score;
                acc[date].count += 1;
                return acc;
            }, {});
    
            return Object.values(groupedByDate).map((item) => ({
                date: item.date,
                score: Math.round((item.score / item.count) * 100) / 100,
                type: item.type,
            })).sort((a, b) => new Date(a.date) - new Date(b.date));
        };
    
        stressAData = averageByDate(stressAData, 'ストレス A');
        stressBData = averageByDate(stressBData, 'ストレス B');
        stressCData = averageByDate(stressCData, 'ストレス C');
    }

    const getLineConfig = (data, minDefault = null) => {
        const max = Math.max(...data.map((item) => item.score));
        const min = Math.min(...data.map((item) => item.score));
        let padding = (max - min) * 0.1;
        if (padding === 0) {
            padding = Math.abs(min) * 0.1;
        }

        return {
            data,
            xField: 'date',
            yField: 'score',
            seriesField: 'type',
            xAxis: {
                type: 'timeCat',
                tickCount: 5,
            },
            yAxis: {
                label: {
                    formatter: (v) => `${v}`,
                },
                max: max + padding,
                min: minDefault ?? (min - padding),
                tickInterval: 1,
            },
            smooth: false,
            point: {
                size: 5,
                shape: 'circle',
            },
            tooltip: {
                showMarkers: true,
                shared: true,
            },
            interactions: [{ type: 'marker-active' }],
        };
    };

    const staffColConfig = {
        data: chartData,
        xField: 'type',
        yField: 'score',
        seriesField: 'type',
        animation: {
            appear: {
                animation: 'scale-in',
                duration: 2000,
            },
        },
    };

    const renderChart = () => {
        return isLineChart ? (
            <>
                <div className='overflow-hidden p-3 w-full lg:w-1/2'>
                    <div className="h-full w-full card rounded-12 p-8 bg-white">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">仕事のストレス要因</p>
                                <Line {...getLineConfig(stressAData)} color={COLOR.stress} />
                            </div>
                        )}
                    </div>
                </div>

                <div className='overflow-hidden p-3 w-full lg:w-1/2'>
                    <div className="h-full card rounded-12 p-8 bg-white">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">心身のストレス反応</p>
                                <Line {...getLineConfig(stressBData)} color={COLOR.health} />
                            </div>
                        )}
                    </div>
                </div>

                <div className='overflow-hidden p-3 w-full lg:w-1/2'>
                    <div className="h-full card rounded-12 p-8 bg-white">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">周囲のサポート</p>
                                <Line {...getLineConfig(stressCData)} color={COLOR.question} />
                            </div>
                        )}
                    </div>
                </div>
            </>
        ) : (
            <>
                <div className='overflow-hidden p-3 w-full lg:w-1/2 bg-white'>
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">スタッフ総合評価</p>
                                <Column {...staffColConfig} />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    return <>{renderChart()}</>;
};
