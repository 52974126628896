import React, { useEffect, useState } from 'react';

const CustomSelect = ({ options, onChange, defaultValue = '' }) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (options) {
            setFilteredOptions(options);
        }
    }, [options]);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setInputValue(inputValue);

        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );

        setFilteredOptions(filteredOptions);
    };

    const handleSelect = (option) => {
        setIsOpen(false);
        setInputValue(option.label);
        onChange(option.value);
    };

    return (
        <div className="relative">
            <input
                type="text"
                tabIndex={1}
                value={inputValue}
                onFocus={() => setIsOpen(true)}
                onChange={handleInputChange}
                onBlur={(e) => {
                    if (e.relatedTarget === null) {
                        setIsOpen(false);
                    }
                }}
                className="hover:border-sky-500 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-96 px-2 py-1 border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />

            {isOpen && (
                <ul
                    tabIndex={2}
                    className="transition-all delay-150 duration-300 mt-1 w-96 bg-white rounded-md shadow-lg max-h-42 overflow-y-auto z-10 absolute"
                >
                    {filteredOptions.map((option) => (
                        <li
                            onClick={() => handleSelect(option)}
                            key={option.value}
                            className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                                inputValue === option.label ? 'bg-sky-100' : ''
                            }`}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;
